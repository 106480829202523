.infoBanner {
  background-color: #bfe0d9;
  padding: 30px 50px;
  width: 80%;
  max-width: 900px;
  margin: 30px auto;
  border: 3px solid #000;
  border-radius: 30px;
}

.infoBannerWrap {
  display: flex;
}

.infoBannerText {
  display: flex;
  flex-grow: 1;
  font-weight: 500;
}

.infoBannerText p {
  margin: 5px 0;
  font-size: 28px;
  font-weight: 600;
  max-width: 500px;
}

.infoBannerButton {
  align-self: center;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .infoBannerWrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin: 10px auto;
  }

  .infoBanner {
    padding: 20px;
    margin: 10px auto;
  }

  .infoBannerItem {
    width: fit-content;
    text-align: center;
    margin: 5px 10px;
  }

  .infoBannerItem h1 {
    font-size: 30px;
  }

  .infoBannerButton {
    align-self: center;
    margin-right: 0;
    margin-top: 10px;
  }
}
