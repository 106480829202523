.mainHeader {
  padding: 70px 0 60px;
  text-align: center;
}

.title {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 15px;
  font-weight: 600;
  max-width: 450px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .mainHeader {
    padding: 50px 0 40px;
  }

  .subtitle {
    padding: 0 15px;
  }
}
