.postWrap {
  width: 80%;
  margin: 80px auto 0;
}

.postHeader {
  text-align: center;
  width: 80%;
  margin: 0 auto 40px;
}

.postHeader h3 {
  margin: 0;
  font-size: 16px;
  color: #ccc;
  margin-bottom: 5px;
}

.postHeader h1 {
  margin: 0;
  font-size: 36px;
}

.postHeader p {
  max-width: 600px;
  margin: 10px auto;
}

.postBody {
  max-width: 700px;
  margin: 0 auto;
}

.backButtonIcon {
  margin-right: 5px;
}

.postOutro {
  text-align: center;
  margin-bottom: 60px;
  margin-top: 50px;
}

strong > img {
  width: 100%;
  max-width: 700px;
}

.loadingWrap {
  display: flex;
  justify-content: center;
  margin: 200px auto;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  text-align: center;
}

@media (max-width: 1024px) {
  .postWrap {
    width: 90%;
    margin: 80px auto 0;
  }
}

@media (max-width: 768px) {
  .postBody {
    width: 90%;
  }

  .postWrap {
    margin: 40px auto 0;
  }

  .postHeader h3 {
    font-size: 16px;
  }

  .postHeader h1 {
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  .postHeader h3 {
    font-size: 14px;
  }

  .postHeader h1 {
    font-size: 28px;
  }
}
