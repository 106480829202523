.teamWrap {
  width: 80%;
  max-width: 950px;
  display: flex;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .teamWrap {
    flex-direction: column;
  }
}
