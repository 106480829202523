.serviceItem {
  text-align: center;
  margin: 0 15px;
  background-color: #1e7b69;
  border-radius: 30px;
  border: 3px solid #000;
  padding: 10px 30px 30px;
  color: #fff;
  width: 300px;
}

.serviceItem h1 {
  margin: 15px auto 25px;
  font-size: 22px;
  max-width: 160px;
}

.serviceItem p {
  font-size: 15px;
  margin: 10px 0 40px;
  font-weight: 500;
  white-space: pre-line;
  text-align: left;
}

@media (max-width: 768px) {
  .serviceItem {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 600px) {
  .serviceItem {
    flex-direction: column;
    margin: 10px auto;
  }
}
