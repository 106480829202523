.teamItem {
  text-align: center;
  margin: 0 10px;
  max-width: 350px;
}

.teamImg {
  width: 180px;
}

.teamItem h1 {
  margin: 15px 0 5px;
  font-size: 28px;
}

.teamItem h3 {
  margin: 0;
  font-size: 18px;
}

.teamItem p {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0 15px;
}

.socials a {
  margin: 5px;
}

.socials img {
  width: 25px;
}

@media (max-width: 768px) {
  .teamItem {
    margin: 15px;
  }
}
