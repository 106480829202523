.InfoWrap {
  width: 80%;
  margin: 0 auto;
  max-width: 550px;
  text-align: center;
}

.InfoWrap h1 {
  font-weight: 600;
  margin: 70px 0 0;
  font-size: 30px;
}

.InfoWrap p {
  margin: 10px 0 30px;
  font-size: 16px;
  font-weight: 500;
  white-space: pre-line;
}

.offersWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1000px;
  margin: 0 auto 50px;
}

.additionServicesWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -30px;
  margin-bottom: 30px;
}

.additionServicesTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
}

.serviceTable {
  width: 90%;
  border-collapse: collapse;
  margin: 0 auto;
}

.serviceTable th,
.serviceTable td {
  padding: 10px;
  text-align: left;
}

.serviceTable th {
  background-color: #f2f2f2;
  font-weight: bold;
  white-space: normal;
  word-wrap: break-word;
}

.even {
  background-color: #f9f9f9;
}

.odd {
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .offersWrap {
    width: auto;
    margin: 0 auto 30px;
  }

  .InfoWrap h1 {
    margin: 50px 0 0;
  }

  .InfoWrap p {
    margin: 10px 0 30px;
  }
}
