.inputWrap {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
  width: 100%;
}
.inputWrap label {
  font-size: 14px;
  font-weight: 700;
  margin-left: 8px;
  margin-bottom: 2px;
}
.inputWrap input,
.inputWrap textarea {
  border: 2px solid #000;
  border-radius: 10px;
  padding: 10px 15px;
  min-width: 200px;
  max-width: 350px;
  width: 100%;
}
input[type="long"] {
  height: 100px;
}

.inputWrap textarea {
  min-height: 250px;
}

.errorText {
  color: red !important;
  margin: 0;
  font-size: 9px !important;
  margin-left: 10px;
}

@media (max-width: 950px) {
  .inputWrap textarea {
    min-height: 80px;
    width: 100%;
  }
}
