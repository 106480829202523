.checkbox {
  display: inline-block;
  cursor: pointer;
  margin: 5px 10px 5px 0;
}

.checkboxLabel {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}

.checkboxInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkboxCheckmark {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 2px solid #000;
  border-radius: 4px;
}

.checkboxCheckmark.checked {
  background-color: #007bff;
  border-color: #007bff;
}

.checkboxCheckmark.checked::before {
  content: "\2713";
  color: #fff;
  font-size: 14px;
}

.checkboxText {
  margin-left: 5px;
}
