.aboutHeader {
  width: 80%;
  margin: 70px auto 100px;
  text-align: center;
}

.aboutHeaderContent h1 {
  font-size: 32px;
  margin: 10px 0 0;
}

.aboutHeaderContent div p {
  font-size: 16px;
  margin: 0 0 50px;
  font-weight: 600;
}
