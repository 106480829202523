.button {
  padding: 13px 20px;
  font-size: 14px;
  background-color: #78281f;
  display: inline-block;
  color: #fef5e7;
  border-radius: 15px;
  display: inline-flex;
}

.button p {
  margin: 0 !important;
  font-weight: 700;
  white-space: nowrap;
}
