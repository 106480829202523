.serviceMiniItem {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.serviceMiniItemLeft {
  margin-right: 25px;
  align-items: center;
  display: flex;
}

.serviceMiniItemLeft img {
  width: 65px;
}

.serviceMiniItemRight h1 {
  font-size: 18px;
  margin: 0;
}

.serviceMiniItemRight p {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0 0;
}
