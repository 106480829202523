.dropdown {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.dropdown label {
  font-size: 14px;
  font-weight: 700;
  margin-left: 8px;
  margin-bottom: 5px;
}

.dropdownToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 10px 15px;
  width: 250px;
  font-size: 14px;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  margin-top: 4px;
  padding: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  list-style-type: none;
  z-index: 1;
  font-size: 14px;
}

.dropdownItem {
  padding: 4px 12px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
}
