.offerItem {
  margin: 0 15px 25px;
  background-color: #1e7b69;
  border-radius: 30px;
  border: 3px solid #000;
  padding: 10px 25px 25px;
  color: #fff;
  max-width: 270px;
  flex: 0 0 33.3%;
  height: fit-content;
}

.offerItemHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 15px;
}

.offerItem img {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
}

.offerItem h1 {
  font-size: 18px;
  max-width: 200px;
  margin: 0;
  font-weight: 700;
}

.offerItem p {
  font-size: 14px;
  margin: 10px 0;
  white-space: pre-line;
}

.offerQuoteWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.offerQuote {
  font-weight: 600;
  font-size: 16px;
  margin: 0 3px 0 0;
  white-space: pre-line;
}

.offerAdditions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.offerAdditions > p {
  margin-right: 5px !important;
  margin-top: -5px !important;
}

.offerAdditions p {
  margin: 0;
}

@media (max-width: 550px) {
  .offerItem {
    flex: auto;
    width: 90%;
    max-width: 350px;
    margin: 0 15px 15px;
  }
}
