.offersWrap {
  width: 80%;
  max-width: 950px;
  display: flex;
  margin: 100px auto 60px;
}

.offersLeft {
  width: 50%;
  margin-right: 40px;
  margin-top: 20px;
}

.offersRight {
  width: 50%;
  margin-left: 40px;
}

.offersRight h1 {
  font-weight: 600;
  font-size: 30px;
  margin-top: 0;
}

.serviceTitleWrap {
  width: 80%;
  margin: 0 auto;
  max-width: 550px;
  text-align: center;
}

.serviceTitleWrap h1 {
  font-weight: 600;
  margin: 0;
  font-size: 28px;
}

.serviceTitleWrap p {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 500;
  white-space: pre-line;
}

.servicesWrap {
  width: 80%;
  max-width: 950px;
  display: flex;
  margin: 30px auto 90px;
  justify-content: center;
  display: flex;
}

@media (max-width: 768px) {
  .offersWrap {
    width: 80%;
    flex-direction: column;
    margin: 30px auto 40px;
  }

  .offersLeft {
    width: 100%;
    margin-left: 0;
  }

  .offersRight {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 650px) {
  .servicesWrap {
    width: 90%;
    margin: 30px auto 40px;
  }
}

@media (max-width: 600px) {
  .servicesWrap {
    flex-direction: column;
  }
}
