.header {
  width: 80%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-top: 20px;
}

.headerLeft {
  width: 20%;
}

.headerMiddle {
  width: 60%;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.headerLinkMenu,
.headerLink {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 20px 0;
}

.signInNav {
  display: none;
}

.headerLinkActive {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 20px 0;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
}

.headerRight {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.linkedInButton {
  width: 120px;
  height: 40px;
  padding: 10px 15px;
  background-color: #0072b1;
  display: flex;
  border-radius: 10px;
  margin-top: 10px;
}

.linkedInText {
  font-size: 14px;
  margin: 0;
  margin-left: 7px;
  color: #fff;
}

.linkedIcon {
  color: #fff;
  margin-right: 5px;
}

.mobile_bars {
  display: none;
  text-align: right;
  font-size: 30px;
  padding-top: 10px;
}

.navIcon {
  color: #000;
}

.servicesMenu {
  background: #78281f;
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  margin-left: -50px;
  top: 65px;
  padding: 15px 0;
  border-radius: 15px;
  color: #fff;
}

.headerLinkMenu {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
}

.headerLinkMenu:hover {
  text-decoration: underline;
}

.signin {
  margin: -5px 0 0 17px;
  font-size: 14px;
  align-self: center;
  font-weight: 600;
  min-width: fit-content;
}

@media (max-width: 1100px) {
  .header {
    width: 90%;
  }

  .headerLink,
  .headerLinkActive {
    font-size: 16px;
    margin: 15px 15px 0;
  }
}

@media (max-width: 800px) {
  .header {
    width: 90%;
  }

  .headerLink,
  .headerLinkActive {
    font-size: 14px;
    margin: 15px 10px 0;
  }

  .signin {
    display: none;
  }

  .signInNav {
    display: block;
    padding-top: 10px;
    border-top: 2px solid #fff;
  }

  .servicesMenu {
    position: relative;
    top: 0;
    margin-left: 0;
  }

  .mobile_bars {
    width: 30px !important;
    display: block;
    position: absolute;
    right: 25px;
    top: 30px;
    padding: 0;
  }

  .headerLink,
  .headerLinkActive {
    color: rgb(229, 228, 230);
    margin: 10px 20px 10px 30px;
    text-align: right;
    font-size: 18px;
  }

  .headerMiddle {
    background: #273746;
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: absolute;
    right: 25px;
    top: 60px;
    padding: 15px 0;
    border-radius: 15px;
    z-index: 10;
  }

  .headerRight {
    width: 50%;
    padding-right: 50px;
  }

  .headerLeft {
    width: 50%;
  }
}
