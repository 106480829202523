.signInHeader {
  width: 80%;
  margin: 100px auto 70px;
  text-align: center;
}

.signInHeader h1 {
  font-size: 34px;
  margin: 10px 0 0;
}

.signInHeader p {
  font-size: 17px;
  margin: 5px 0 50px;
  color: #999;
}

.signInInputWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}

.signInInputWrap > div {
  margin-bottom: 15px;
}
