.infoBanner {
  background-color: #bfe0d9;
  padding: 20px;
  width: 80%;
  max-width: 900px;
  margin: 30px auto;
  border: 3px solid #000;
  border-radius: 30px;
}

.infoBannerWrap {
  display: flex;
}

.infoBannerItem {
  width: 33%;
  text-align: center;
}

.infoBannerItem h1 {
  margin: 5px 0;
  font-size: 26px;
  font-weight: 600;
}

.infoBannerItem p {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .infoBannerWrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  .infoBanner {
    padding: 15px;
    margin: 10px auto;
  }

  .infoBannerItem {
    width: fit-content;
    text-align: center;
    margin: 5px 10px;
  }
}
