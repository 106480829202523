.workWrap {
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  margin: 20px auto 40px;
}

.workItemImage {
  margin: 0;
  width: 50%;
  height: intrinsic;
  align-self: center;
}

.workItemContent {
  margin-left: 50px;
  width: 50%;
  margin-top: 10px;
}

.workItemContent h1 {
  margin: 0 0 15px;
  font-size: 28px;
  font-weight: 600;
}

.workItemDesc {
  font-size: 16px;
  margin: 10px 0 15px;
}

.workOptions {
  display: flex;
  align-items: center;
}

.letschat {
  display: flex;
  align-items: center;
  margin-left: 25px;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 700px) {
  .workWrap {
    width: 90%;
  }

  .workItemContent {
    margin-left: 30px;
  }

  .workItemContent h1 {
    font-size: 22px;
  }

  .workItemDesc {
    font-size: 12px;
  }

  .workItemImage {
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  .workWrap {
    flex-direction: column;
  }

  .workItemImage {
    margin: 0 auto;
    width: 90%;
  }

  .workItemContent {
    width: 80%;
    margin: 10px auto 0;
    max-width: 400px;
  }

  .workItemContent h1 {
    font-size: 30px;
  }

  .workItemDesc {
    font-size: 18px;
  }
}
