.sectionHeader {
  display: flex;
  width: 80%;
  max-width: 1000px;
  margin: 60px auto 100px;
  justify-content: center;
}

.reversed {
  display: flex;
  flex-direction: row-reverse;
}

.sectionHeaderImg {
  width: 55%;
}

.sectionHeaderImg img {
  width: 100%;
}

.sectionHeaderInfo {
  width: 45%;
  margin-right: 100px;
  align-self: center;
  max-width: 400px;
}

.reversed .sectionHeaderInfo {
  margin-right: 0;
  margin-left: 100px;
}

.sectionHeaderInfo h1 {
  font-size: 34px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px;
}

.sectionHeaderInfo p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .sectionHeader {
    flex-direction: column;
    margin: 30px auto 50px;
  }

  .reversed {
    flex-direction: column-reverse;
  }

  .sectionHeaderImg {
    width: 100%;
  }

  .sectionHeaderInfo {
    width: 100%;
    margin-right: 0;
    margin: 10px 0;
    max-width: 500px;
  }

  .sectionHeaderInfo > a {
    display: none;
  }

  .reversed .sectionHeaderInfo {
    margin-right: 0;
    margin-left: 0;
  }
}
