.letsChatWrap {
  width: 80%;
  margin: 0 auto;
  max-width: 830px;
}

.titleWrap {
  font-size: 17px;
  margin-top: 60px;
  margin-left: -5px;
}

.formWrap {
  display: flex;
  flex-direction: row;
}

.formLeftWrap {
  width: 70%;
  margin-right: 50px;
}

.formRightWrap {
  width: 30%;
}

.aboutInputsWrap {
  margin-top: 35px;
}

.aboutInputsWrapMargin {
  margin-right: 30px;
  width: 100%;
}

.aboutInputsWrap > div {
  display: flex;
  flex-direction: row;
}

.servicesInfoWrap {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 10px;
}

.servicesInfoWrap h1 {
  font-size: 13px;
  margin: 0;
  margin-right: 5px;
}

.servicesInfoWrap p {
  font-size: 12px;
  margin: 0;
  margin-top: 1px;
}

.servicesInfoError {
  color: red !important;
  margin: 0;
  font-size: 9px !important;
  margin-left: 10px;
}

.detailsInputsWrap {
  width: 250px;
  align-items: flex-start;
}

.detailsInputsWrap p {
  font-size: 13px;
  color: #666;
  margin-bottom: 15px;
}

.formSubmit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: -30px;
}

.formSubmit > p {
  margin: 0;
  margin-right: 20px;
  font-size: 13px;
  color: #666;
  align-self: center;
}

.servicesOptionsWrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.serviceWrap {
  flex: 1 0 31%; /* explanation below */
  border: 2px solid #000;
  border-radius: 10px;
  padding: 10px 15px;
  width: 150px;
  background-color: #86c7ba;
  margin: 5px;
  cursor: pointer;
}

.serviceWrap h1 {
  margin: 0;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.ReCAPTCHA {
  justify-content: flex-end;
  display: flex;
  margin-top: 10px;
}

.loadingWrap {
  display: flex;
  justify-content: center;
  margin: 200px auto;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  text-align: center;
}

.loadingWrap h1 {
  margin: 0;
}

@media (max-width: 950px) {
  .formWrap {
    flex-direction: column;
  }

  .formLeftWrap {
    width: 100%;
    margin-right: 0;
  }

  .formRightWrap {
    width: 100%;
  }

  .aboutInputsWrap > div {
    margin-top: 0;
  }

  .detailsInputsWrap {
    width: 100%;
  }

  .titleWrap h1 {
    margin-bottom: 0;
  }
}

@media (max-width: 550px) {
  .aboutInputsWrap > div {
    flex-wrap: wrap;
  }

  .aboutInputsWrapMargin {
    margin-right: 0;
  }
}
