.footer {
  background-color: #78281f;
  padding: 50px 0;
  margin-top: 100px;
}

.footerWrap {
  width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.footer_left {
  width: 33%;
  padding-top: 5px;
}

.footer_middle,
.footer_right {
  color: #fff;
  width: 33%;
  justify-content: center;
  display: flex;
}

.footer_right {
  justify-content: flex-end;
}

.footerLogoImg {
  width: 150px;
}

.copyrightText p {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.footerSocialIcon {
  width: 28px;
  margin-right: 10px;
}

.footer_middle div h3,
.footer_right div h3 {
  margin: 0;
  font-size: 18px;
}

.footer_right div p,
.footerLink {
  margin: 7px 0;
  max-width: 250px;
  color: #fff;
}

.footerLink:hover {
  text-decoration: underline;
}

.footer_right div p {
  display: flex;
}

.footerLinkWrap {
  display: flex;
  flex-direction: column;
}
.icons {
  margin-right: 8px;
}

.footerTag {
  text-align: center;
  color: #fff;
  width: 50%;
  margin: 35px auto 0;
}

.footerTag div p {
  margin: 10px 10px;
  font-size: 20px;
  font-weight: 600;
}

.footerTag div {
  display: inline-flex;
}

.footerTag p {
  margin: 0;
  font-size: 14px;
}

@media (max-width: 1100px) {
  .footer {
    margin-top: 60px;
  }

  .footerWrap {
    width: 90%;
    flex-wrap: wrap-reverse;
    max-width: 600px;
  }
  .footer_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  .footer_middle,
  .footer_right {
    width: 50%;
    justify-content: center;
  }

  .footer_middle div h3,
  .footer_right div h3 {
    font-size: 22px;
  }

  .footerTag {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .footer_middle {
    width: 70%;
  }

  .footer_middle,
  .footer_right {
    width: 100%;
    justify-content: flex-start;
    max-width: 250px;
    margin: 0 auto;
    text-align: center;
  }

  .footer_middle {
    margin: 30px auto 10px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .footer_right div p {
    justify-content: center;
  }
}
