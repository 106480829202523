.modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.open {
  display: flex;
}

.modalWrap {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-width: 585px;
  width: 100%;
  margin: 10px;
}

h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.submitBtn:hover {
  background-color: #0056b3;
}

.closeButton {
  padding: 0;
  background-color: transparent;
  color: #000;
  border: none;
  cursor: pointer;
  height: 25px;
}

.modalTitleWrap {
  display: flex;
  flex-direction: row;
}

.modalTitleWrap h2 {
  flex-grow: 1;
}

.modalFooterWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.modalFooterWrap > p {
  margin: 0;
  align-self: center;
  margin-right: 10px;
  font-size: 12px;
  color: red;
}

.modalResultWrap {
  margin: 0 15px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: right;
}

.modalResultWrap > p {
  margin-bottom: 0;
}

.modalResultWrap .estimateDisclaimer {
  font-size: 9px;
  width: 300px;
  align-self: flex-end;
  margin-bottom: 10px;
}

.modalContentWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modalSubContentWrap {
  padding: 5px 10px;
}

.modalSubContentWrap p {
  margin: 0;
  font-weight: 700;
  margin-left: 10px;
  font-size: 16px;
  margin-bottom: 5px;
}

@media (max-width: 950px) {
  .modalWrap {
    max-width: 310px;
  }
}

@media (max-width: 450px) {
  .modalResultWrap .estimateDisclaimer {
    width: 250px;
  }
}
